import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

export default function LayoutApp() {
  return (
    <>
      <main>
        <>
          <Outlet />
        </>
      </main>

      <Footer />
    </>
  );
}
