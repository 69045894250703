import { useState } from "react";
import Hero from "./Hero";
import Video from "./Video";

export default function Home() {
  const [isVideo, setIsVideo] = useState(() => {
    if (window.localStorage.getItem("nutritionData")) {
      return true;
    } else {
      return false;
    }
  });

  return <>{isVideo ? <Video /> : <Hero setIsVideo={setIsVideo} />}</>;
}
